<template>
    <div class="card card-custom example example-compact">
        <div class="card-header">
            <h3 class="card-title">{{title}}</h3>
            <button type="button" class="close" @click="cancelForm()">
                <i aria-hidden="true" class="ki ki-close"></i>
            </button>
        </div>

        <!--begin::Form-->
        <form class="form fv-plugins-bootstrap fv-plugins-framework" ref="dataForm" id="dataForm">
            <div class="card-body" style="max-height:500px;overflow:auto;">

                <div>
                    <b-tabs content-class="mt-3">
                        <b-tab title="数量统计详情" active>
                            <div class="form-group table-responsive">
                                <table class="table" style="overflow:hidden;">
                                    <thead>
                                    <tr class="alert alert-light">
                                        <th scope="col">SKU</th>
                                        <th scope="col">属性</th>
                                        <th scope="col">数量</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                        <template v-for="(item, idx) in data_form.skus">
                                            <tr :key="idx" v-if="item.quantity > 0">
                                                <td>{{item.sku}}</td>
                                                <td>
                                                    <template v-for="attr in JSON.parse(item.attr_info)">
                                                        <span :key="attr" class="label label-primary label-inline font-weight-lighter mr-2">{{attr}}</span>
                                                    </template>
                                                </td>
                                                <td>
                                                    {{item.quantity}}
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </b-tab>
                        <b-tab title="货架分配详情">
                            <div class="form-group table-responsive">
                                <table class="table" style="overflow:hidden;">
                                    <thead>
                                    <tr class="alert alert-light">
                                        <th scope="col" width="30%">SKU</th>
                                        <th scope="col" width="30%">详情</th>
                                        <th scope="col">货架</th>
                                        <th scope="col">价格</th>
                                        <th scope="col">数量</th>
                                    </tr>
                                    </thead>
                                    <tbody>

                                    <template v-for="(item, idx) in data_form.details">
                                        <tr :key="idx" v-if="item.quantity > 0">
                                            <td>
                                                <div  class="text-dark-65 font-weight-bold line-height">
                                                    {{item.sku}}
                                                </div>
                                                <div style="margin-top:10px;"  v-if="item.checked"><span class="label label-danger label-inline">盘</span></div>
                                                <div style="margin-top:10px;">
                                                    <template v-for="attr in JSON.parse(item.attr_info)">
                                                        <span :key="attr" class="label label-primary label-inline font-weight-lighter mr-2">{{attr}}</span>
                                                    </template>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="text-dark-65  line-height">
                                                    物料编号：{{item.material_number}}
                                                </div>
                                                <div class="text-dark-65  line-height">
                                                    创建时间：{{timeToDate(item.create_time)}} &nbsp;&nbsp;&nbsp;&nbsp; {{item.creator}}
                                                </div>
                                                <div class="text-dark-65  line-height" v-if="item.operate_time">
                                                    修改时间：{{timeToDate(item.operate_time)}} &nbsp;&nbsp;&nbsp;&nbsp; {{item.operator}}
                                                </div>
                                                <div class="text-dark-65  line-height" v-if="item.purchasement_number">
                                                    采购单号：{{item.purchasement_number}}
                                                </div>
                                            </td>
                                            <td>
                                                {{item.shelf_number}}
                                            </td>
                                            <td>{{item.price}}</td>
                                            <td>
                                                <span v-if="item.checked">{{item.quantity}}</span>
                                                <input v-else type="text" @input="onIntInput" name="quantity"  v-model="data_form.details[idx].quantity" class="form-control" placeholder="">
                                            </td>
                                        </tr>
                                    </template>
                                    </tbody>
                                </table>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
            </div>

            <div class="card-footer">
                <button ref="data_form_submit" class="btn btn-primary mr-2">保存</button>
                <button class="btn btn-secondary" type="reset" @click="cancelForm()">关闭</button>
            </div>
        </form>
        <!--end::Form-->
    </div>
</template>
<script>

    import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
    import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
    import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
    import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
    import KTUtil from "@/assets/js/components/util";

    import {getStockDetail, updateStock, getStockSku} from '@/api/stock';

    export default {
        name: "stock_detail",
        props: {
            title: String,
            dialog_data: Object
        },
        created:function() {
            var _this = this;
            getStockDetail({'warehouse_id': _this.data_form.warehouse_id, 'product_id': _this.data_form.product_id}).then(function(res) {
                if(res && res.response){
                    _this.data_form.details = res.response;
                }
            });

            getStockSku({'warehouse_id': _this.data_form.warehouse_id, 'product_id': _this.data_form.product_id}).then(function(res) {
                if(res && res.response){
                    _this.data_form.skus = res.response;
                }
            });
        },
        components :{

        },
        watch: {

        },
        data() {
            return {
                data_form:{
                    warehouse_id: this.dialog_data.form_data.warehouse_id,
                    product_id: this.dialog_data.form_data.product_id,
                    details: [],
                    skus: []
                },
            }
        },
        mounted() {
            const data_form = KTUtil.getById("dataForm");

            //表单数据验证
            this.fv = formValidation(data_form, {

                plugins: {
                    trigger: new Trigger(),
                    submitButton: new SubmitButton(),
                    bootstrap: new Bootstrap()
                }
            });
            this.fv.on("core.form.valid", () => {
                var _this = this;

                //按钮加载动画
                const submitButton = this.$refs["data_form_submit"];
                submitButton.classList.add("spinner", "spinner-light", "spinner-right");

                updateStock(_this.data_form).then(function (res){
                    if (res) {
                        KTUtil.toast(_this, '结果提醒', '保存成功', 'success');
                        data_form.reset();
                        _this.$emit('opeareResultHandler', 1);
                    }
                    submitButton.classList.remove(
                        "spinner",
                        "spinner-light",
                        "spinner-right"
                    );
                });
            });
        },
        methods: {
            cancelForm(){
                this.$emit('opeareResultHandler', 0);

            },
            regionChange (data) {
                this.data_form.struct_address = data;
            },
            onFloatInput(e){
                e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
            },
            onIntInput(e){
                e.target.value = parseInt(e.target.value) || null;
            },
            timeToDate(value) {
                return KTUtil.timestamp2date(value);
            }
        }
    }
</script>
